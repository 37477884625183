<template>
    <div class="section  login-2">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6 ">
                    <div class="form-section">                        
                        <div class="heading_main text_align_left">
                            <h2 v-html="pageTitle"></h2>
                        </div>
                        <form action="" method="POST" @submit.prevent="registrationFormSubmit" autocomplete="off">
                            <div class="form-group form-box" id="name_block">
                            <input type="text" :placeholder="placeholderText('name')" maxlength="25" v-model.trim="$v.formData.name.$model">
                                <i class="icon-feather-user"></i>
                                <p class="text-danger form-error" v-if="!formStatus && $v.formData.name.$error && !$v.formData.name.required">{{ validationRequired('name.required') }}</p>
                                <p class="text-danger form-error" v-if="!formStatus && $v.formData.name.$error && !$v.formData.name.minLength">{{ validationRequired('name.invalid') }}</p>
                            </div>
                            <div class="form-group form-box" id="email_block">
                                <input type="email" :placeholder="placeholderText('email')" maxlength="50" v-model.trim="$v.formData.email.$model" >
                                <i class="icon-material-baseline-mail-outline"></i>
                                <p class="form-error text-danger" v-if="!formStatus && $v.formData.email.$error && !$v.formData.email.required">{{ validationRequired('email.required') }}</p>
                                <p class="form-error text-danger" v-if="!formStatus && $v.formData.email.$error && !$v.formData.email.email">{{ validationRequired('email.invalid') }}</p>
                            </div>
                            <div class="form-group form-box" id="phone_block">
                                <input type="tel" :placeholder="placeholderText('phone')" maxlength="20" v-model.trim="$v.formData.phone.$model">
                                <i class="icon-feather-phone"></i>
                                <p class="form-error text-danger" v-if="!formStatus && $v.formData.phone.$error && !$v.formData.phone.required">{{ validationRequired('phone.required') }}</p>
                                <p class="form-error text-danger" v-if="!formStatus && $v.formData.phone.$error && !$v.formData.phone.numeric">{{ validationRequired('phone.invalid') }}</p>
                            </div>
                            <div class="form-group form-box" id="password_block">
                                <input type="password" :placeholder="placeholderText('password')" maxlength="25" autocomplete="off" v-model.trim="$v.formData.password.$model">
                                <i class="icon-material-outline-https"></i>
                                <p class="form-error" v-if="!formStatus && $v.formData.password.$error && !$v.formData.password.required">Please Enter your password!</p>
                                <p class="form-error" v-if="!formStatus && $v.formData.password.$error && !$v.formData.password.minLength">Password must be {{$v.formData.password.$params.minLength.min}} digits or long!</p>
                            </div>
                            <div class="form-group form-box" id="password_confirmation_block">
                                <input type="password" :placeholder="placeholderText('confirm_password')" name="password_confirmation" maxlength="25" autocomplete="off" v-model.trim="$v.formData.password_confirmation.$model">
                                <i class="icon-material-outline-https"></i>
                                <p class="form-error" v-if="!formStatus && $v.formData.password_confirmation.$error && !$v.formData.password_confirmation.sameAsPassword && !$v.formData.password_confirmation.required">Password and confirm Password should be same!</p>
                            </div>
                            <div class="form-group form-box" id="course_block">
                                <Select2 
                                    v-model="selected" 
                                    :options="options" 
                                    :settings="{ 
                                        multiple: true,
                                        minimumResultsForSearch: 20,
                                        placeholder: 'Select Course'
                                    }" 
                                    @change="handleChange($event)" 
                                />
                            </div>
                            <div class="form-group mb-0 clearfix">
                                <button type="submit" class="btn-md btn-theme float-left gradient-btn register-btn" :data-title="button">{{ button }}</button>
                            </div>  
                            <div class="resMssg text-center mt-3" v-html="formResponse"></div>
                        </form>
                    </div>
                </div>                
            </div>
        </div>
    </div>
</template>


<script>
import { required, minLength, email, sameAs, numeric } from 'vuelidate/lib/validators'
import Translation from '../../../public/translation.json'
import Select2 from 'v-select2-component';
import axios from "../../axios.js";

export default {
    components: { 
        Select2
    },
    data() {
        return {
            formData: {
                name: '',
                email: '',
                phone:'',
                password:'',
                password_confirmation: '',
                courses: []
            },
            formResponse: '',
            formStatus: true,
            defaultLang: localStorage.getItem('_store_lang') || 'en',
            selected: [1],
            options: []
        }
    },
    validations: {
        formData: {
            name: {
                required,
                minLength: minLength(4)
            },
            email: {required,email},
            phone: {required, numeric},
            password: {required, minLength: minLength(6)},
            password_confirmation:{sameAsPassword: sameAs('password')},
        }
    },
    computed: {
        button() {
            return Translation.button[this.defaultLang].register
        },
        pageTitle() {
            return Translation.pagetitle[this.defaultLang].register
        }
    },
    methods: {
        placeholderText(field){
            return Translation.placeholder[this.defaultLang][field]
        },
        validationRequired(field){
            return Translation.validation[this.defaultLang][field]
        },
        registrationFormSubmit(){
            this.formStatus = false
            this.$v.$touch()
            if(!this.$v.$invalid){      
                document.querySelector('.resMssg').style.display = 'block'
                this.formResponse = '';
                document.querySelector('.register-btn').setAttribute('disabled', true)
                document.querySelector('.register-btn').textContent = 'Please wait...';
                
                this.$store.dispatch('registration', this.formData)
                    .then(res=>{
                        if(res.status === 200 && res.data.token){
                            this.formResponse =`<span class="alert alert-success">${res.data.message}</span>`      
                            this.resetForm()                        
                            setTimeout(()=>{
                                window.location.href = `/user/dashboard`
                            }, 3000);                        
                        }
                        else {
                            this.formResponse =`<span class="alert alert-danger">${res.data.error}</span>`
                        }
                        
                    }).catch(err=>{
                        document.querySelector('.register-btn').removeAttribute('disabled')
                        document.querySelector('.register-btn').textContent = 'Register';
                        if(err.response.status === 500)
                            this.formResponse =`<span class="alert alert-danger">Internal Server Error!</span>`;
                        else if(err.response.status === 422)
                            this.formResponse =`<span class="alert alert-danger">${err.response.data.message}</span>`;
                        else
                            this.formResponse =`<span class="alert alert-danger">${err.response.data.error}</span>`;

                        this.togellingResponse();
                    })
            }
        },
        togellingResponse(){
            setTimeout(()=>{
                document.querySelector('.resMssg').style.display = 'none'
            }, 5000)
        },
        resetForm(){
            Object.keys(this.formData).forEach(item=>{
                this.formData[item] = ''
            })  
            //this.formResponse = ''
            this.formStatus = true
        },
        handleChange(val){
            this.formData.courses = val;
        },
        getCourses() {
            axios.get('course/get-course-for-registration')
            .then(res=>{
                if(res.data.status === true) {
                    const courseData = res.data.data;
                    this.options = courseData.map(row=>{
                        return {
                            id: row.id,
                            text: row.title
                        }
                    })
                }
            })
            .catch(err=>{
                console.log(err.response.data.error)
            })
        }
    },
    created: function() {
        this.getCourses();
    }
}
</script>

<style scoped>
.form-error{
    color: #f00; font-size: 0.7rem;
}
.input__field{height:50px;}
.error-stroke{stroke:#f00;}
.login-wrapper .input--nao {
    margin-bottom: 0;
    padding: 10px 0;
}
</style>